import React from 'react';
// import sections
import GenericSection from '../components/sections/GenericSection';
import FeaturesSplit02 from '../components/sections/FeaturesSplit02';
import FeaturesSplitTreatments
  from '../components/sections/FeatureSplitTreatments';
// import some required elements
import Image from '../components/elements/Image';
import Button from '../components/elements/Button';
import Accordion from '../components/elements/Accordion';
import AccordionItem from '../components/elements/AccordionItem';
import {Link} from 'react-router-dom';

class Treatments extends React.Component {

  state = {
    demoModalActive: false
  }

  componentDidUpdate() {
    //topHeader
    const selection = this.props.match.params.treatment;
    const anchor = document.querySelector('#' + selection);
    if (anchor !== null && anchor !== undefined) {
      const scrollToY = anchor.getBoundingClientRect().top - 60;
      const content = anchor.getElementsByClassName("accordion-content")[0];
      content.style.maxHeight = content.children[0].clientHeight + "px";
      window.scrollTo(0, scrollToY);
    }
  }

  render() {

    const selection = this.props.match.params.treatment;
    const url = "https://buchung.treatwell.de/ort/cesur-berlin/";

    return (
      <React.Fragment>
        <FeaturesSplitTreatments invertMobile imageFill />
        <GenericSection>
          <div>
            <Accordion >
              <AccordionItem title="Cesur Specials" id="specials" active={selection === 'specials'} >
              Lassen Sie sich von uns persönlich beraten und finden Sie für Ihre Haut die passende Behandlung. 
              Dabei untersuchen wir nicht nur Ihre Haut, sondern auch Ihre Pflege sowie Ernährung. 
              Denn auch eine falsche Pflege und Ernährung kann zu Hautirritationen führen. 
              Zu unserem umfangreichen Beratungsangebot passen perfekt unsere Combi-Pakete, die Ihre Haut zum Strahlen bringen werden. 
              Unsere Combi-Pakete wie D.Microdermabrasion combi GlowPeel und Hydrafacial combi D.Microdermabrasion sind für alle Hauttypen geeignet und bieten einen 2 in 1 Effekt. 

                <p style={{ marginTop: "28px" }}><b>Rötung:</b> 1-2 Tage </p>
                <p><b>Unsere Empfehlung:</b> In den nächsten 24 Stunden nach der Behandlung kein Make-Up aufzutragen, sich zu sonnen oder stark zu schwitzen. </p>

                <figure>
                  <Image
                    src={require('./../assets/images/cesur/cesura-5.jpg')}
                    alt="Cesur Special" />
                </figure>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Cesur Beratung (15 Min.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">25€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Hydrafacial Combi D.Microdermabrasion (1 Std. 20 Min.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">179€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>


                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Diamant Microdermabrasion Combi GlowPeel (1 Std.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">119€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Basic Facial Combi Glow Peel (50 Min.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">99€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Basic Facial mit ASA Peeling (50 Min.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">109€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div style={{ paddingBottom: "20px", paddingTop: "20px" }} className="container">
                  <Link style={{textDecoration: "none"}} to={{ pathname: url}} target="_blank">
                  <Button color="dark" wideMobile>Special Buchen</Button>
                  </Link>
                </div>

              </AccordionItem>

              <AccordionItem title='Hydrafacial' id="hydrafacial" active={selection === 'hydrafacial'}>
                Die Anti-Aging Revolution aus den USA ist eine hoch effektive Methode zur Tiefenreinigung und dauerhaften Auffrischung der Haut.
                Ihre Haut wird sichtbar und spürbar straffer. Der Teint leuchtet durch die Reparatur von Sonnenschäden und fortgeschrittenen Alterserscheinungen
                kräftiger und gesünder. Durch die innovative Dermalinfusion und eine zusätzliche Lichttherapie wird ein verbessertes Hautbild geschaffen.
                <br></br>
                <br></br>

            Unsere Hydrafacial Arbeitsschritte:
             <ul>
                  <div>• Abtragung von abgestorbenen Hautzellen</div>
                  <div>• Sanftes Säurepeeling zur Porenaufweichung</div>
                  <div>• Tiefenausreinigung der Hautporen</div>
                  <div>• Befreiung der Haut von gelösten Talgablagerungen</div>
                  <div>• Patentierte Hydration mit Antioxidantien, Vitaminen und Hyaluronsäure</div>
                  <div>• Bekämpfung fortgeschrittener Alterserscheinungen der Haut</div>
                  <div>• Sichtbare Verbesserung der Elastizität, Feinheit und Glattheit der Haut.</div>
                </ul>

                <p style={{ marginTop: "28px" }}><b>Rötung</b> 1-2 Tage </p>
                <p><b>Unsere Empfehlung:</b> In den nächsten 24 Stunden nach der Behandlung kein Make-Up aufzutragen, sich zu sonnen oder stark zu schwitzen. </p>

                <figure>
                  <Image
                    src={require('./../assets/images/cesur/cesura-14.jpg')}
                    alt="Hydrafacial Deluxe" />
                </figure>


                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Hydrafacial (50 Min.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">159€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Hydrafacial Deluxe inkl. Hals & Dekolleté (1 Std.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">199€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Hydrafacial Circadia Fleming Peptide Maske (1 Std.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">289€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Hydrafacial mit Wirkstoffampulle (1 Std.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">189€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div style={{ paddingBottom: "20px", paddingTop: "20px" }} className="container">
                <Link style={{textDecoration: "none"}} to={{ pathname: url }} target="_blank">
                  <Button color="dark" wideMobile>Hydrafacial Buchen</Button>
                </Link>
                </div>
              </AccordionItem>


              <AccordionItem title="Microneedeling" id="needeling" active={selection === 'needeling'}>
                Die Microneedeling Behandlung ist der Jungbrunnen für unsere Haut.
                Bei dieser Behandlung wird die Haut mit kleinen Nadeln minimal verletzt.
                Durch die Reizung der oberen Epidermis entstehen Microverletzungen, die die Haut dazu anregen,
                ihre Selbstheilungskräfte zu aktivieren und so die Kollagen Produktion anzuregen.
                Als Folge der Kollagenbildung wird das Bindegewebe gekräftigt, die Durchblutung der Haut wird gesteigert
                und sie gewinnt an Straffheit und Elastizität zurück.

                <br></br>
                <br></br>

                Microneedeling eignet sich bestens für:
              <ul>
                  <div>• Narben</div>
                  <div>• Falten, zur Verminderung der Faltentiefe</div>
                  <div>• Erschlaffte Haut, zur Straffung</div>
                  <div>• Sonnengeschädigte Haut, zur Hauterneuerung und Hautverjüngung</div>
                  <div>• Großporige Haut, zur Verfeinerung des Hautbildes</div>
                </ul>

                <p style={{ marginTop: "28px" }}><b>Rötung:</b> 3-6 Tage </p>
                <p><b>Unsere Empfehlung:</b> In den nächsten 24 Stunden nach der Behandlung kein Make-Up aufzutragen, sich zu sonnen oder stark zu schwitzen. </p>

                <figure>
                  <Image
                    src={require('./../assets/images/3rd-party/face-2.jpg')}
                    alt="Micro Needeling" />
                </figure>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Microneedeling (1 Std.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">169€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Microneedeling inkl. Hals & Dekolleté (1 Std. 20 Min.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">199€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Radiofrequenz-Microneedling (1 Std. 30 Min.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">399€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div style={{ paddingBottom: "20px", paddingTop: "20px" }} className="container">
                <Link style={{textDecoration: "none"}} to={{ pathname: url }} target="_blank">
                  <Button color="dark" wideMobile>Needeling Buchen</Button>
                </Link>
                </div>
              </AccordionItem>

{/*               <AccordionItem title="BB Glow" id="glow" active={selection === 'glow'} >
                Ein einmaliges Meso-Konzentrat mit aktiven Pflanzenwirkstoffen, straffenden Peptiden und ausgleichendem Whitening Komplex.
                In Kombination mit einem Hauch natürlicher Eisenoxit.
                Für einen natürlichen "No-Make-up Look", schon vor dem Aufstehen.
                <br></br>
                <br></br>

                  BB Glow wirkt:
              <ul>
                  <div>• Aufhellend</div>
                  <div>• Ausgleichend</div>
                  <div>• Verfeinernd</div>
                  <div>• Glättend</div>
                </ul>


                <p style={{ marginTop: "28px" }}><b>Rötung:</b> Keine </p>
                <p><b>Unsere Empfehlung:</b> In den nächsten 24 Stunden nach der Behandlung sollten Sie es vermeiden sich zu sonnen sowie stark zu schwitzen. </p>


                <figure>
                  <Image
                    src={require('./../assets/images/3rd-party/face-1.jpg')}
                    alt="BB Glow" />
                </figure>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">BB Glow (1 Std.)</p>
                  <p style={{ fontWeight: "bold" }} class="alignright">129€</p>
                </div>
                <div style={{ clear: 'both' }}></div>

                <div style={{ paddingBottom: "20px", paddingTop: "20px" }} className="container">
                <Link style={{textDecoration: "none"}} to={{ pathname: url }} target="_blank">
                  <Button color="dark" wideMobile>BB Glow Buchen</Button>
                </Link>
                </div>

              </AccordionItem> */}
              <AccordionItem title="Diamant Microdermabrasion" id="microdermabrasion" active={selection === 'microdermabrasion'}>
                Sie möchten Ihr Hautbild langfristig verbessern?
                Dann ist die Diamant Microdermabrasion genau das richtige für Sie.
                Bei diesem schmerzfreien Verfahren wird die oberste Hautschicht gezielt abgetragen,
                um abgestorbene Hautzellen und Verhornungen zu entfernen.
                Auf diese Weise lässt sich das Hautbild sichtbar verfeinern und glätten.

                <br></br>
                <br></br>

                Effekte der Behandlung:
              <ul>
                  <div>• Elastizität der Haut nimmt zu</div>
                  <div>• Verfeinerung der Poren</div>
                  <div>• Festigkeit der Haut nimmt zu</div>
                  <div>• Reduzierung der Faltentiefe</div>
                  <div>• Aktivierung der hauteigenen Collagen- und Elastinproduktion</div>
                </ul>



                <p style={{ marginTop: "28px" }}><b>Rötung:</b> 1 Tag </p>
                <p><b>Unsere Empfehlung:</b> In den nächsten 24 Stunden nach der Behandlung sollten Sie es vermeiden sich zu sonnen sowie stark zu schwitzen.
                Nach der Diamant Microdermabrasion Behandlung kann es in den ersten Tagen zu einer Verschlechterung des Hautbildes kommen,
                da durch die Behandlung die Unreinheiten an die Oberfläche gebracht werden.</p>

                <figure>
                  <Image
                    src={require('./../assets/images/3rd-party/face-7.jpg')}
                    alt="Diamant Microdermabrasion" />
                </figure>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Diamant Microdermabrasion (1 Std.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">99€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div style={{ paddingBottom: "20px", paddingTop: "20px" }} className="container">
                <Link style={{textDecoration: "none"}} to={{ pathname: url }} target="_blank">
                  <Button color="dark" wideMobile>Microdermabrasion Buchen</Button>
                </Link>
                </div>
              </AccordionItem>

              <AccordionItem title="Fruchtsäurepeeling ASA peel KLAPP" id="fruchtsäurepeeling" active={selection === 'fruchtsäurepeeling'} >
                Erleben Sie ein neues Hautgefühl mit dem Fruchtsäurepeeling ASA peel KLAPP. 
                Diese Behandlung befreit die Haut von verdickten Hautschichten und verbessert den Feuchtigkeitsgehalt Ihrer Haut.
                <br></br>
                <br></br>
                Vorteile der Fruchtsäurepeeling ASA peel KLAPP Behandlung:
              <ul>
                  <div>• Schwächt Pigmentflecken ab</div>
                  <div>• Mildert Fältchen und Unebenheiten</div>
                  <div>• Steigert die Collagen- und die Elastianproduktion</div>
                  <div>• Stimuliert die Energieversorgung</div>
                  <div>• Verfeinert das Hautbild</div>
                  <div>• Vermindert Unreinheiten</div>
                </ul>

                <p style={{ marginTop: "28px" }}><b>Rötung:</b> Keine </p>
                <p><b>Unsere Empfehlung:</b> In den nächsten 24 Stunden nach der Behandlung sollten Sie es vermeiden sich zu sonnen sowie stark zu schwitzen. </p>

                <figure>
                  <Image
                    src={require('./../assets/images/3rd-party/face-6.jpg')}
                    alt="Facial" />
                </figure>
                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Fruchtsäurepeeling ASA peel KLAPP (1 Std.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">109€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div style={{ paddingBottom: "20px", paddingTop: "20px" }} className="container">
                <Link style={{textDecoration: "none"}} to={{ pathname: url }} target="_blank">
                  <Button color="dark" wideMobile>Fruchtsäurepeeling Buchen</Button>
                </Link>
                </div>
              </AccordionItem>

              <AccordionItem title="Nadelfreie Mesoproationen" id="mesoproationen" active={selection === 'mesoproationen'} >
                Faltenfrei ganz ohne OP! Die Nadelfreie Mesotherapie ist die neuste Verjüngungsmethode zum Klären, Glätten, Aufhellen, Aufbauen und Festigen der Haut.
                Diese Methode bekämpft Hautdefizite wie Falten, Schwellungen, Erschlaffung und Pigmentstörungen mit effektiven Wirkstoffen wie beispielsweise Hyaluronsäure,
                Peptide und Retinol.

                <p style={{ marginTop: "28px" }}><b>Rötung:</b> Keine </p>
                <p><b>Unsere Empfehlung:</b> In den nächsten 24 Stunden nach der Behandlung sollten Sie es vermeiden sich zu sonnen sowie stark zu schwitzen. </p>

                <figure>
                  <Image
                    src={require('./../assets/images/3rd-party/face-5.jpg')}
                    alt="Facial" />
                </figure>
                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Nadelfreie Mesoproationen (1 Std.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">99€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div style={{ paddingBottom: "20px", paddingTop: "20px" }} className="container">
                <Link style={{textDecoration: "none"}} to={{ pathname: url }} target="_blank">
                  <Button color="dark" wideMobile>Mesoproationen Buchen</Button>
                </Link>
                </div>
              </AccordionItem>

              <AccordionItem title="Facials" id="facials" active={selection === 'facials'} >
                Sie haben ein Date oder wollen einfach mal entspannen und eine kurze Auszeit genießen?
                Dann nichts wie los!
                Lassen Sie sich von unseren Facial Angeboten verwöhnen.
                Ob Tiefenreinigung, Behandlung von Aknenarben sowie Hyperpigmentierung oder den extra schnellen Wow-Effekt, wir haben für jeden Hauttyp das passende Facial parat.

                <p style={{ marginTop: "28px" }}><b>Rötung:</b> Keine </p>
                <p><b>Unsere Empfehlung:</b> In den nächsten 24 Stunden nach der Behandlung sollten Sie es vermeiden sich zu sonnen sowie stark zu schwitzen. </p>

                <figure>
                  <Image
                    src={require('./../assets/images/3rd-party/face-8.jpg')}
                    alt="Facial" />
                </figure>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Basic Gesichtsbehandlung (50 Min.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">79€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Medium Gesichtsbehandlung & Massage (1 Std.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">89€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Premium Gesichtsbehandlung inkl. Hals und Dekolleté & Massage (1 Std. 20 Min.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">109€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div style={{ paddingBottom: "20px", paddingTop: "20px" }} className="container">
                <Link style={{textDecoration: "none"}} to={{ pathname: url }} target="_blank">
                  <Button color="dark" wideMobile>Facial Buchen</Button>
                </Link>
                </div>
              </AccordionItem>

              <AccordionItem title="Augenbrauen & Wimpern" id="augenbrauen" active={selection === 'augenbrauen'}>
                Schöne Augenbrauen und einen schwungvollen Wimpernaufschlag im Handumdrehen. 
                Ob Straight, Rounded oder Arched wir bringen Ihre Augenbrauen wieder auf Vordermann.
                Zudem wollen Sie volle und lange Wimpern, ganz ohne Mascara? Dann buchen Sie zusätzlich unser Wimpernlifting mit anschließender Färbung.
                
                <figure>
                  <Image
                    src={require('./../assets/images/3rd-party/face-3.jpg')}
                    alt="Augenbrauen & Wimpern" />
                </figure>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Augenbrauen Waxing/Threading (30 Min.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">15€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Oberlippen Waxing/Threading (20 Min.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">ab 8€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Henna Brows (40 Min.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">39€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Augenbrauen Färben (30 Min.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">15€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Wimpern Färben (30 Min.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">15€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Browlifting mit Keratinboost (1 Std.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">69€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Wimpernlifting mit Färben (1 Std.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">69€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div style={{ paddingBottom: "20px", paddingTop: "20px" }} className="container">
                <Link style={{textDecoration: "none"}} to={{ pathname: url }} target="_blank">
                  <Button color="dark" wideMobile>Behandlung Buchen</Button>
                </Link>
                </div>
              </AccordionItem>

              <AccordionItem title="Permanent Make-Up" id="permanent" active={selection === 'permanent'}>
                Morgens strahlend frisch aussehen, ganz ohne Make-up aufzutragen. 
                Mit unserem Permanent Make-up betonen wir die Konturen Ihrer Augen, Augenbrauen oder Lippen auf ganz natürlicherweise. 
                Dabei werden bei dieser Behandlung feinste Farbpartikel in die oberste Hautschicht eingebracht. 
                Lassen Sie sich gerne von uns beraten, welche Farbe Ihre natürliche Schönheit noch mehr zum Leuchten bringt.
                
                <figure>
                  <Image
                    src={require('./../assets/images/3rd-party/face-10.jpg')}
                    alt="Permanent Make-Up" />
                </figure>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Lidstrich (2 Std.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">ab 349€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Puderbrows (2 Std.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">ab 459€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Microblading (2 Std.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">ab 459€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Wimpernkranz oben oder unten (1 Std. 30 Min.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">ab 299€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Lippen wie Aquarell (2 Std.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">ab 499€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Lippen Vollschattierung (2 Std.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">ab 549€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">3 Zonen PMU (6 Std.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">ab 999€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div style={{ paddingBottom: "20px", paddingTop: "20px" }} className="container">
                <Link style={{textDecoration: "none"}} to={{ pathname: url }} target="_blank">
                  <Button color="dark" wideMobile>Behandlung Buchen</Button>
                </Link>
                </div>
              </AccordionItem>

              <AccordionItem title="Laser-Dauerhafte Haarentfernung" id="haarentfernung" active={selection === 'haarentfernung'}>
                Wer kennt es nicht? Ständiges rasieren ist überaus lästig und auch die Haut wird dadurch stark beansprucht.
                Die tägliche Rasur führt gerade bei sensibler Haut oftmals zu unschönen Rötungen. Damit ist nun endlich Schluss! Unerwünschte Haare können durch pulsierende,
                intensive Lichtstrahlen entfernt werden. Dabei dringt der Laser zu jedem Haarfollikel vor und hemmt das zukünftige Haarwachstum.
                Erleben Sie endlich eine haarlose Zeit.

                <p style={{ marginTop: "28px" }}><b>Rötung:</b> 1-2 Tage </p>
                <p><b>Unsere Empfehlung:</b> In den nächsten 24 Stunden nach der Behandlung sollten Sie es vermeiden sich zu sonnen. Die Behandlung sollte am Anfang alle 4-8 Wochen wiederholt werden. </p>

                <figure>
                  <Image
                    src={require('./../assets/images/3rd-party/face-9.jpg')}
                    alt="Laser-Dauerhafte Haarentfernung" />
                </figure>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Achseln (25 Min.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">69€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Oberlippe & Kinn (20 Min.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">39€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Gesicht (20 Min)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">49€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Nacken (30 Min.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">69€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Bikini - Nur Damen (30 Min.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">99€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Bikini & Intim - Nur Damen (50 Min.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">149€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Bauch (30 Min.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">79€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Beine - oben (30 Min.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">99€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Beine - unten (30 Min.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">99€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Brust (40 Min.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">99€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div id="textbox">
                  <p style={{ fontWeight: "bold" }} class="alignleft">Rücken (40 Min.)</p>
                  {/* <p style={{ fontWeight: "bold" }} class="alignright">129€</p> */}
                </div>
                <div style={{ clear: 'both' }}></div>

                <div style={{ paddingBottom: "20px", paddingTop: "20px" }} className="container">
                <Link style={{textDecoration: "none"}} to={{ pathname: url }} target="_blank">
                  <Button color="dark" wideMobile>Haarentfernung Buchen</Button>
                </Link>
                </div>
              </AccordionItem>
            </Accordion>
          </div>
        </GenericSection>
        <FeaturesSplit02 invertMobile imageFill className="illustration-section-06" />
      </React.Fragment>
    );

  }
}

export default Treatments;