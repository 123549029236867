import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from './partials/Logo';
import FooterNav from './partials/FooterNav';
import FooterSocial from './partials/FooterSocial';
import Image from '../elements/Image';

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
}

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
}

class Footer extends React.Component {

  render() {
    const {
      className,
      topOuterDivider,
      topDivider,
      ...props
    } = this.props;

    const classes = classNames(
      'site-footer invert-color',
      topOuterDivider && 'has-top-divider',
      className
    );

    const Mailto = ({ email, subject, body, children }) => {
      return (
        <a style={{ marginLeft: "15px" }} href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>{children}</a>
      );
    };

    return (
      <footer
        {...props}
        className={classes}
      >
        <div className="container">
          <div className={
            classNames(
              'site-footer-inner',
              topDivider && 'has-top-divider'
            )}>
            <div className="footer-top text-xxs">
              <div className="footer-blocks">
                <div className="footer-block">
                  <Logo className="mb-16" />
                  <FooterSocial />

                  {/* <div className="footer-copyright">&copy; 2020 Spike, all rights reserved</div> */}
                </div>
                <div className="footer-block">
                  <div className="footer-block-title">CESUR</div>
                  <ul className="list-reset mb-0">
                    <li>
                      <a href="/">Willkommen</a>
                    </li>
                    <li>
                      <a href="/treatments/">Behandlungen</a>
                    </li>
                    <li>
                      <a href="/pricelist/">Services</a>
                    </li>
                  </ul>
                </div>
                <div className="footer-block">
                  <div className="footer-block-title">Kontakt</div>
                  <ul className="list-reset mb-0">
                    <li>
                      CESUR Berlin
                    </li>
                    <li>
                      Linienstraße 45
                    </li>
                    <li>
                      10119 Berlin
                    </li>
                    <li style={{ paddingTop: "20px" }}>
                      <div id="textbox">
                        <Image className="alignleft" style={{ paddingTop: "2px" }}
                          src={require('./../../assets/images/layout/phone.svg')}
                          alt="Phone-Contact"
                          width={17}
                          height={17} />
                        {/* <p style={{ marginLeft: "30px" }}>030 25763020</p> */}
                        <a style={{ marginLeft: "15px" }} href="tel:+49-30-257-630-20">030 25763020</a>
                      </div>
                    </li>
                    <li>
                      <div id="textbox">
                        <Image className="alignleft" style={{ paddingTop: "5px" }}
                          src={require('./../../assets/images/layout/mail.svg')}
                          alt="Mail-Contact"
                          width={17}
                          height={17} />
                        <Mailto email="hello@cesur-berlin.de" subject="" body="Liebes Cesur-Team,">
                          hello@cesur-berlin.de
                       </Mailto>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="footer-block">
                  <div className="footer-block-title">Öffnungszeiten</div>
                  <ul className="list-reset mb-0">
                    <li>
                      Montag: 12-18 Uhr
                    </li>
                    <li>
                      Dienstag-Freitag: 10-18 Uhr
                    </li>
                    <li>
                      Samstag: Nach Vereinbarung
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer-bottom space-between center-content-mobile text-xxs">
              <FooterNav />

            </div>
          </div>
        </div>
      </footer>
    )
  }
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;